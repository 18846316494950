import React, { useState } from "react";


import './Upload.css';
export default function Upload() {

  const [DataUri, setDataUri] = useState('');
  const [prevItems, setprevItems] = useState([]);
  


  function handlePicTaken(e) {
    //console.log(e.target.files[0]);
    
    var reader  = new FileReader();
    reader.onloadend = function(){
      //preview.src = reader.result;
      
      setDataUri(reader.result);

      let temp=[];
      temp.push(<img className="previewList-item" key={`img1`}  src={reader.result} alt="uploaded"/>);
      setprevItems(temp);
  }
    reader.readAsDataURL(e.target.files[0]);



   
  }



  return (
    <div>
          <div>
          <input type="file" id="camfile" accept="image/*" capture="camera" onChange={handlePicTaken} />
          <label htmlFor="camfile" className="camfile">Camera</label>
          </div>

          
          {DataUri
          ?<div className="previewList">
          {prevItems}
        </div>
          :null
          }

          
    
 
 
    </div>
    
  );
}