import React, { useEffect,useState,useRef } from "react";
import axios from 'axios';
import "./Home.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button,Panel,Modal,DropdownButton,MenuItem,Glyphicon  } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


export default function Home(props) {
  const isMountedRef = useRef(null);
  const { SearchBar } = Search;
  const LISTING_ENDPOINT=`${props.SERVER_URL}/leasingList`;
  const UPLOAD_ENDPOINT=`${props.SERVER_URL}/leasing`;
 
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const [Data, setData] = useState([]);
  const [DataSelected, setDataSelected] = useState(null);
  const [PhotoSelected, setPhotoSelected] = useState(null);
  const [prevSource, setprevSource] = useState('');
  const [percentCompleted, setpercentCompleted] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [ModalBody, setModalBody] = useState(null);
  const [title, setTitle] = useState('Όλες οι');

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const products = [  ];
  const columns = [{
    dataField: 'Id',
    text: 'ID',
    hidden: true
  },{
    dataField: 'CompanyCode',
    text: 'CompanyCode',
    hidden: true
  },{
    dataField: 'SalesCode',
    text: 'SalesCode',
    hidden: true
  },{
    dataField: 'CarValidate',
    text: 'CarValidate',
    hidden: true
  },{
    dataField: 'CompanyTitle',
    text: 'ΕΤΑΙΡΕΙΑ',
    sort: true
  },{
    dataField: 'DeliveryCode',
    text: 'ΑΔΤ',
    sort: true
  }, {
    dataField: 'DeliveryDate',
    text: 'ΗΜ/ΝΙΑ',
    sort: true
  }];


  

  useEffect(() => {
   
    if(!props.isAuthenticated){
      props.history.push("/login");
     }

     isMountedRef.current = true;
     /*
const REJECT   = 1;
const PENDING  = 2;
const REVIEW   = 3;
const COMPLETE = 4;

     */
    props.setIsLoading(true);
    setIsLoading(true);
     const data={sortBy:'DeliveryDate',orderBy:'DESC'};

     axios.post(LISTING_ENDPOINT, data)
     .then((response) => {
      setIsLoading(false);
      props.setIsLoading(false);
      //console.log(response);
        if(response.status === 200 && response.data.data){
        
          
            let data=response.data.data;
            let temp=[];
            for(let i=0;i<data.length;i++){
              if(data[i].Status==1 || data[i].Status==2){
                //only pending and rejected
                temp.push(data[i]);
              }
            }
           setTotalData(data);
           
            setData(temp);
           
          
        }
        if(response.status === 200 && response.data.error){
         
          setModalBody(GetModalBody(false));
      setShowModal(true);

        }

        if(response.status === 401){
         
            delete axios.defaults.headers.common["Authorization"];
              props.userHasAuthenticated(false);
              localStorage.removeItem("access_token");
              localStorage.removeItem("user");
              props.history.push("/login");
          
        }
      }, (error) => {
        props.setIsLoading(false);
        setIsLoading(false);
        //if(error.response.status === 401){
         
          delete axios.defaults.headers.common["Authorization"];
            props.userHasAuthenticated(false);
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            props.history.push("/login");
        return;
      //}

        
       
        /*setModalBody(GetModalBody(false));
    setShowModal(true);*/
      });




     return () => { 
      isMountedRef.current = false;
      
    }
   
  }, []);


 
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      
      setDataSelected(row);
    }
  };

 
  

  const rowStyle = (row, rowIndex) => {
    return {backgroundColor: row.Status===1 ?'red' :rowIndex % 2 === 0 ? '#ccc' : '#fff'  };
  };



function handleRefresh(e){
  if(isLoading || !props.isOnline){
    return;
  }
  var elem=e.target;
  elem.classList.add(`spinning`);
//console.log(e.target)

  props.setIsLoading(true);
  setIsLoading(true);
   const data={sortBy:'DeliveryDate',orderBy:'DESC'};

   axios.post(LISTING_ENDPOINT, data)
   .then((response) => {
    elem.classList.remove(`spinning`);
    setIsLoading(false);
    props.setIsLoading(false);
   
    //console.log(response);
      if(response.status === 200 && response.data.data){
      
       
          let data=response.data.data;
          let temp=[];
          for(let i=0;i<data.length;i++){
            if(data[i].Status==1 || data[i].Status==2){
              //only pending and rejected
              temp.push(data[i]);
            }
          }
         setTotalData(data);
         
          setData(temp);
         
          setTitle('Όλες οι');
        
      }
      if(response.status === 200 && response.data.error){
       
        setModalBody(GetModalBody(false));
    setShowModal(true);

      }

      if(response.status === 401){
        delete axios.defaults.headers.common["Authorization"];
            props.userHasAuthenticated(false);
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            props.history.push("/login");
        
      }
    }, (error) => {
      elem.classList.remove(`spinning`);
      props.setIsLoading(false);
      setIsLoading(false);
     // if(error.response.status === 401){
       
        delete axios.defaults.headers.common["Authorization"];
          props.userHasAuthenticated(false);
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          props.history.push("/login");
      //return;
    //}
      /*setModalBody(GetModalBody(false));
  setShowModal(true);*/
    });
    
}








function GoBack(){
  setDataSelected(null);
  setPhotoSelected(null);
  setpercentCompleted(0);
}

function handlePicTaken(e) {
  //console.log(e.target.files[0]);
  
  setPhotoSelected(e.target.files[0]);

  var reader  = new FileReader();
  reader.onloadend = function(){
     setprevSource(reader.result);
  
  }
  reader.readAsDataURL(e.target.files[0]);

 
}

function Upload(){
  setIsLoading(true);
  props.setIsLoading(true);
  const config = {
    onUploadProgress: function(progressEvent) {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
     
      setpercentCompleted(percentCompleted);
    }
  }
  var formData = new FormData();
  formData.append("Image", PhotoSelected);
  formData.append("Id", DataSelected.Id);
  const data={Id:DataSelected.Id,Image:PhotoSelected};//,filter:{Status:1}};

  axios.post(UPLOAD_ENDPOINT, formData,config,{
    headers: {
        'Content-Type': 'multipart/form-data'
    }})
  .then((response) => {
    setIsLoading(false);
    props.setIsLoading(false);
     if(response.status === 200){
     
          if(response.data.error){
            GoBack();
            setModalBody(GetModalBody(false));
            setShowModal(true);
            return;
          }
          if(response.data.status=='success' && response.data.data){
            let temp=totalData;

            for(var i=0; i < temp.length; i++) {
              if(temp[i].Id == DataSelected.Id)
              {
                  temp.splice(i,1);
              }
            }
            setTotalData(temp);
            let temp1=[];
            for(let i=0;i<temp.length;i++){
              if(temp[i].Status==1 || temp[i].Status==2){
                //only pending and rejected
                temp1.push(temp[i]);
              }
            }

            setData(temp1);


            GoBack();
            setModalBody(GetModalBody(true));
            setShowModal(true);
          }

     }
     if(response.status === 401){
      delete axios.defaults.headers.common["Authorization"];
           props.userHasAuthenticated(false);
           localStorage.removeItem("access_token");
           localStorage.removeItem("user");
           props.history.push("/login");
       
     }
   }, (error) => {
    props.setIsLoading(false);
        setIsLoading(false);
        //if(error.response.status === 401){
         
          delete axios.defaults.headers.common["Authorization"];
            props.userHasAuthenticated(false);
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            props.history.push("/login");
       // return;
      //}
    /*setModalBody(GetModalBody(false));
    setShowModal(true);*/
   });


}

function GetModalBody(success){
  if(success){
    return (
      <div>
        Η φωτογραφία ανέβηκε με επιτυχία!
      </div>
    );
  }else{
    return (
      <div>
        Συνέβη ένα σφάλμα. Δοκιμάστε ξανά σε λίγο.
      </div>
    );
  }
}

function handleSelect(e){

let temp=[];
if(e==1 || e==2){
e==1?setTitle('Απορριφθείσες'):setTitle('Εκκρεμείς');
            for(let i=0;i<totalData.length;i++){
              if(totalData[i].Status==e){
                
                temp.push(totalData[i]);
              }
            }
}else if(e==-1){
  setTitle('Όλες οι')
  for(let i=0;i<totalData.length;i++){
    if(totalData[i].Status==1 || totalData[i].Status==2){
      //only pending and rejected
      temp.push(totalData[i]);
    }
  }
}
            setData(temp);
}
 
  return (
    <div className="Home">
      
      <Modal show={showModal} onHide={handleClose} >
       
  <Modal.Body>{ModalBody}</Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={handleClose}>
            ΚΛΕΙΣΙΜΟ
          </Button>
        </Modal.Footer>
      </Modal>
     
      <div className="lander" style={{display:!DataSelected?`block`:`none`}}>
        <table style={{width:'100%',marginBottom:'10px'}}>
          <tbody>
            <tr>
            <td><Glyphicon glyph="refresh" style={{fontSize:'30px'}} onClick={handleRefresh} /></td>
              <td><h4>{title} συναλλαγές</h4></td>
              <td>
              <DropdownButton bsStyle="default" title="ΟΛΕΣ" pullRight onSelect={handleSelect} id="select-status">
              <MenuItem eventKey="-1">ΟΛΕΣ</MenuItem>
      <MenuItem eventKey="2">ΕΚΚΡΕΜΕΙΣ</MenuItem>
      <MenuItem eventKey="1">ΑΠΟΡΡΙΦΘΕΙΣΕΣ</MenuItem>
      </DropdownButton>

              </td>
            </tr>
          </tbody>
        </table>


        <ToolkitProvider
  keyField="Id"
  data={ Data }
  columns={ columns }
  
  search
>
       
        {/*<BootstrapTable keyField='Id' data={ Data } columns={ columns } rowEvents={ rowEvents } rowStyle={rowStyle} pagination={ paginationFactory() }/>*/}
        {
    props => (
      <div>
        
        <SearchBar { ...props.searchProps } placeholder="Αναζήτηση..."/>
        
        <BootstrapTable
          { ...props.baseProps } rowEvents={ rowEvents } rowStyle={rowStyle} pagination={ paginationFactory() } 
        />
      </div>
    )
  }


        </ToolkitProvider>

       
      </div>
      
      <div className="lander" style={{display:DataSelected?`block`:`none`}}>
      <Panel>
        <Panel.Heading>
          <table style={{width:'100%'}}>
            <tbody>
            <tr>
              <td>Συναλλαγή {DataSelected && DataSelected.SalesCode}   </td>
              <td><Button onClick={GoBack}>Πίσω</Button></td>
            </tr>
            </tbody>
          </table>
          </Panel.Heading>
        <Panel.Body>
          {DataSelected && !PhotoSelected
          ?<div style={{width:'100%',textAlign:'left'}}>

            ΚΑΤΑΣΤΑΣΗ: {DataSelected.Status==1?<span style={{color:`red`}}>ΑΠΟΡΡΙΦΘΗΚΕ</span>:<span style={{color:`green`}}>ΣΕ ΕΚΚΕΡΕΜΟΤΗΤΑ</span>}<br/>
            ΕΤΑΙΡΕΙΑ: <strong>{DataSelected.CompanyTitle}</strong><br/>
            ΚΩΔ. LEASING: <strong>{DataSelected.CompanyCode}</strong><br/>
            ΚΩΔ. ΠΑΡΑΓΓΕΛΙΑΣ: <strong>{DataSelected.SalesCode}</strong><br/>

            ΑΡ. ΔΕΛΤΙΟΥ ΑΠΟΣΤΟΛΗΣ: <strong>{DataSelected.DeliveryCode}</strong><br/>
            ΗΜ/ΝΙΑ ΑΠΟΣΤΟΛΗΣ: <strong>{DataSelected.DeliveryDate}</strong><br/>
            ΚΩΔ. ΕΠΙΒΕΒΑΙΩΣΗΣ: <strong>{DataSelected.CarValidate}</strong><br/>
            ΚΑΤΑΝΑΛΩΤΗΣ: <strong>{DataSelected.ConsumerValidate}</strong><br/>
            <div style={{textAlign:'center'}}>
            <input type="file" id="camfile" accept="image/*" capture="camera" onChange={handlePicTaken} />
            <label htmlFor="camfile" className="camfile">Φωτογραφία</label>
            </div>




          </div>
          :null

          }

          {DataSelected && PhotoSelected
          ?<div style={{width:'100%',textAlign:'left'}}>


            Εταιρεία: <strong>{DataSelected.CompanyTitle}</strong><br/>
            ΑΡ. ΔΕΛΤΙΟΥ ΑΠΟΣΤΟΛΗΣ: <strong>{DataSelected.DeliveryCode}</strong><br/>

            <div style={{textAlign:'center',paddingTop:'10px'}}>
             <p> <img src={prevSource} alt='' style={{maxWidth:'200px'}}/></p>
                        <LoaderButton
              
              type="button"
              bsSize="large"
              isLoading={isLoading}
              isOnline={props.isOnline}
              disabled={isLoading}
              onClick={Upload}
            >
              Ανέβασμα Φωτογραφίας
            </LoaderButton>



            </div>




          </div>
          :null

          } 








        </Panel.Body>
      </Panel>
        
      </div>




     











    </div>
  );
}