import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import "./LoaderButton.css";
//import { Button, Icon } from 'semantic-ui-react'

export default function LoaderButton({
  isLoading,
  isOnline,
  className = "",
  disabled = false,
  ...props
}) {
  return (
    <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading || !isOnline}
    {...props}
  >
    {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
    
    {props.children}
  </Button>


);




}