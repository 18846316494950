import React, { useState,useEffect } from "react";
import {  FormGroup, FormControl, ControlLabel,Modal,Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import axios from 'axios';
import "./Login.css";



export default function Login(props) {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
 
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
   
   
    if(localStorage.getItem("access_token")){
      
      props.userHasAuthenticated(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
      props.history.push("/");
    }
   
    
  }, []);
  const [fields, handleFieldChange] = useFormFields({
    uname: "",
    password: ""
  });
  function validateForm() {
    return fields.uname.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();


    setIsLoading(true);
    const data={username:fields.uname,password:fields.password,source:'app'};
    const LOGIN_ENDPOINT=`${props.SERVER_URL}/login`;

    /*console.log(data);"source": "app"
    fetch(LOGIN_ENDPOINT, {  
        method: 'POST',  
        headers: {  
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },  
         body: JSON.stringify({
        uname: fields.uname,
        pass: fields.password,
      })
    })
    .then(function (data) {  
        setIsLoading(false);
      console.log('Request success: ', data);  
    })  
    .catch(function (error) {  
        setIsLoading(false);
      console.log('Request failure: ', error);  
    });*/
    
    
    try {

       let response = await axios.post(LOGIN_ENDPOINT, data);
       
        //console.log(response);
       /*setIsLoading(false);
        return;*/
        if(response.status === 200 && response.data.token){
            let token = response.data.token;
            localStorage.setItem("access_token", token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            //console.log(response.data.user,localStorage.getItem("access_token"));
          /*
//response.user
{"Fullname":"Test Dealer","Firstname":"Test","Lastname":"Dealer","Email":"","Username":"testdealer"}
          */



            setIsLoading(false);
            props.userHasAuthenticated(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
            props.history.push("/");
        }else{
            setIsLoading(false);
            //alert('Wrong');
            setShowModal(true);
        }
        
        


    } catch(e){
        //console.log(e);
        //alert('Wrong');
        setIsLoading(false);
        setShowModal(true);
    }
    //props.history.push("/");
    //success    props.userHasAuthenticated(true);
  }
  
  return (
   
    <div className="Login">
  <Modal show={showModal} onHide={handleClose} >
       
       <Modal.Body>Λάθος κωδικός ή όνομα χρήστη</Modal.Body>
             <Modal.Footer>
              
               <Button variant="primary" onClick={handleClose}>
                 ΚΛΕΙΣΙΜΟ
               </Button>
             </Modal.Footer>
           </Modal>





     <form onSubmit={handleSubmit}>
      <FormGroup controlId="uname" bsSize="large">
          <ControlLabel>Username</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={fields.uname}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
  
  type="submit"
  bsSize="large"
  isLoading={isLoading}
  isOnline={props.isOnline}
  disabled={!validateForm()}
>
  Σύνδεση
</LoaderButton>
      </form>
    </div>
  );
}