import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Upload from "./containers/Upload";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
export default function Routes({ appProps }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      <AppliedRoute path="/login" exact component={Login} appProps={appProps} />
      <AppliedRoute path="/upload" exact component={Upload} appProps={appProps} />
     
      { /* Finally, catch all unmatched routes */ }
      
      <Route component={NotFound} />
    </Switch>
  );
}